h1,
h2.large {
	font-size: 4.8rem;
	line-height: 115%;
}

h2 {
	font-size: 3.6rem;
	line-height: 115%;
	padding-bottom: 1.6rem;
}

h3 {
	font-size: 2.4rem;
	line-height: 125%;
}

h4 {
	font-size: 2rem;
}

p.large {
	font-size: 1.8rem;
}

header {
    width: 96%;
	padding: 1.2rem 2.4rem;
}


footer {
    padding: 2.4rem;
}

.hero {
   padding: 2.4rem;
}

.hero nav {
    top: 2.4rem;
    left: 3.2rem;
    right: 3.2rem;
}

.section .inner {
    padding: 0 2.4rem;
}

.section .inner .content {
	padding: 0;
}

.content {
	padding: 0 2.4rem;
}

.z-grid-container {
    gap: 4.8rem;
	align-items: flex-start;
}

.z-grid-container .details {
	font-size: 1.4rem;
	line-height: 150%;
}

.hero.about .inner {
    padding: 0 2.4rem 0 4.8rem;
}

.clear.xl,
.clear.lg {
	height: 2.4rem !important;
}

.btn.sticky {
    top: 1.2rem;
    left: 1.2rem;
    padding: 1.2rem 2.4rem;
}

.hero.contact {
	padding: 2.4rem;
}

.hero.contact {
    height: auto;
    min-height: 70rem;
}

.hero.contact .inner {
    background-size: auto 60%;
    background-repeat: no-repeat;

	min-height: 0rem;
    padding: 4.8rem;
}

.work-grid {
    grid-template-columns: repeat(2,1fr);
}

.service {
    padding: 2.4rem;
	font-size: 1.4rem;
	line-height: 150%;
}

.service p {
	padding-bottom: 1.6rem;
}

.service .service-thumb {
    height: 18rem;
}

.service .service-thumb img {
	transform: scale(0.6, 0.6);
}

.service .columns {
	flex-flow: row wrap;
}

.service .columns ul {
	width: 100%;
	margin-left: 1.6rem;
}

.accordion {
    max-width: 100%;
}

.grid {
    gap: 2.4rem;
    margin-bottom: 4.8rem;
}

.hero.shop .copy .sticker {
    top: -8rem;
    left: -1.6rem;
}

.hero.shop {
    padding: 20rem 0 4.8rem 0;
}

.hero.shop .copy {
    padding: 0 4.8rem;
}