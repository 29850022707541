/* ========================================================================== *\
   TABLE OF CONTENTS
\* ==========================================================================

	1. RESET
	2. GENERAL TAGS
        2.1 COLOURS
	3. STRUCUTRE
		3.1 HERO
		3.2 WORK
            3.2.1 CASE STUDIES
		3.3 ABOUT
		3.4 SERVICES
        3.5 FAQ
        3.6 CONTACT
        3.7 SHOP
	4. GENERAL CLASSES
	5. COLUMNS
*/

/* ========================================================================== *\
   1. RESET
\* ========================================================================== */

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, font, img, ins, kbd, q, s, samp,
small, strike, sub, sup, tt, var,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-weight: inherit;
	font-style: inherit;
	font-size: 100%;
	font-family: inherit;
	vertical-align: baseline;
}
/* remember to define focus styles! */
:focus {
	outline: 0;
}
body {
	line-height: 1;
	color: black;
	background: white;
}
ol, ul {
	list-style: none;
}
/* tables still need 'cellspacing="0"' in the markup */
table {
	border-collapse: separate;
	border-spacing: 0;
}
caption, th, td {
	text-align: left;
	font-weight: normal;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: "";
}
blockquote, q {
	quotes: "" "";
}

/* Make HTML 5 elements display block-level for consistent styling */
main, article, section, aside, hgroup, nav, header, footer, figure, figcaption {
  display: block;
}

/* ========================================================================== *\
   2. GENERAL TAGS
\* ========================================================================== */

* {
	box-sizing: border-box;
}

html {
	font-size: 62.5%; /* == 10px rem == */
}

html, body {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
}

body {
	font-size: 160%; /* == 16px em == */
	line-height: 150%;
	color: var(--cobalt);
	background: #fff;
	font-family: "Poppins", sans-serif;
}

p {
	padding-bottom: 2.4rem;
}

p.large {
	font-size: 2rem;
	line-height: 150%;
}

p.XLarge {
    font-size: 2.4rem;
    line-height: 150%;
}

p.small {
    font-size: 1.4rem;
    line-height: 150%;
}

.true-blue {
    color: var(--true-blue);
}

ul.bullets {
    display: block;
    margin: 0 0 2.4rem 2.4rem;
}

ul.bullets ul {
    margin-top: 1.6rem;
}

ul.bullets li {
    display: list-item;
    list-style-type: disc;
    margin-bottom: 0.4rem;
}

h1,
h2.large {
	display: block;
	font-size: 6rem;
	line-height: 125%;
	padding-bottom: 1.6rem;
}

h2 {
	font-size: 5.2rem;
	line-height: 125%;
	padding-bottom: 2.4rem;
}

h3 {
	font-size: 3.2rem;
	line-height: 150%;
	padding-bottom: 1.6rem;
}

h4 {
	font-size: 2.4rem;
	line-height: 150%;
	padding-bottom: 1.6rem;
}

a {
    transition: all .3s ease-in-out;
	-webkit-transition: all .3s ease-in-out;
	-ms-transition: all .3s ease-in-out;
	color: var(--true-blue);
}

a:before,
a:after {
	transition: all .3s ease-in-out;
	-webkit-transition: all .3s ease-in-out;
	-ms-transition: all .3s ease-in-out;
}

a:hover {
	outline: 0;
}

:focus {
	outline: 3px solid var(--turquoise);
}

hr {

}

blockquote {

}

::selection {
	background: var(--turquoise);
	color: var(--cobalt);
}

::-moz-selection {
	background: var(--cobalt);
	color: #ffffff;
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* ====================================== *\
   2.1 COLOURS
\* ====================================== */

:root {
    --cobalt: #002931;
    --true-blue: #0080A1;
    --light-blue: #CDE6EC;
    --blood-red: #DE1018;
    --mustard-yellow: #F39F0B;
    --white: #ffffff;
    --turquoise: #30bfb2;
}

/* ========================================================================== *\
   3. STRUCTURE
\* ========================================================================== */

.skip-to-content-link {
	display: block;
	text-align: center;
	font-weight: bold;
	padding: 10px 20px;
	background: #ffffff;
	box-shadow: 0px 5px 10px rgba(0,0,0,0.4);
	left: 10px;
	top: 10px;
	position: absolute;
	z-index: 999999;
	transform: translateY(-200%);
	transition: transform 0.3s;
}

.skip-to-content-link:focus {
	transform: translateY(0%);
}

.logo {
    display: block;
    width: 18rem;
    margin: 0 auto 2.4rem auto;
}

.hamburger {
    width: 32px;
    height: 32px;
    display: none;
    flex-direction: column;
    justify-content: space-around;
    cursor: pointer;
    z-index: 10;
}

.hamburger.active {
    position: absolute;
    top: 4.8rem;
    right: 3.2rem;
}

.line {
    width: 100%;
    height: 4px;
    border-radius: 0.2rem;
    background-color: white;
    transition: transform 0.3s, opacity 0.3s;
}

.line.top.active {
    transform: translateY(10px) rotate(45deg);
}

.line.middle.active {
    opacity: 0;
}

.line.bottom.active {
    transform: translateY(-11px) rotate(-45deg);
}

header.expand nav {
    display: block;
}

header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 2.4rem;
    align-items: center;

    padding: 1.2rem 4.8rem;
    border-radius: 1.2rem;
    border: 4px solid var(--white);
    background: rgba(0,41,49,0.9);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);

    position: fixed;
    top: 1.6rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999999;
    width: 120rem;
    transition: all .3s ease-in-out;
	-webkit-transition: all .3s ease-in-out;
	-ms-transition: all .3s ease-in-out;
}

header.hidden {
    transform: translateY(-150%) translateX(-50%);
}

header.visible {
    transform: translateY(0) translateX(-50%);
}

header .logo-mark {
    width: 7.2rem;
    display: block;
}

header nav {
    display: block;
    width: 100%;
}

header nav ul {
    display: flex;
    flex-direction: row;
    gap: 3.2rem;
    justify-content: center;
    align-items: center;
    width: 100%;
}

header nav ul li {
    display: block;
}

header nav ul li:first-child {
    margin-left: auto;
}

header nav ul li:last-child {
    margin-left: auto;
}

header nav ul li .btn {
    padding: 0.8rem 2.4rem;
    font-size: 1.4rem;
    border: none;
}

header nav ul li a {
    color: var(--white);
    font-weight: normal;
    text-decoration: none;
    font-size: 1.6rem;
    overflow: hidden;
    display: block;
    position: relative;
    overflow: hidden;
}

header nav ul li a:after {
    content: "";
    display: block;
    width: 100%;
    height: 0.2rem;
    background: var(--turquoise);
    position: absolute;
    left: 100%;
    bottom: 0;
}

header nav ul li a:hover:after {
    left: 0;
}

footer {
    display: flex;
    flex-direction: row;
    gap: 2.4rem;
    justify-content: space-between;
    align-items: center;
    padding: 2.4rem 4.8rem 2.4rem 4.8rem;
}

footer div {
    display: block;
    min-width: 21rem;
}

footer div:last-child {
    text-align: right;
}

.footer-logo {
    display: block;
    width: 10rem;
    margin-bottom: 2.4rem;
}

footer a:hover {
    text-decoration-thickness: 2px;
}

.page-enter {
    opacity: 0;
    transform: translateX(100%);
}

.page-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms ease, transform 300ms ease;
}

.page-exit {
    opacity: 1;
    transform: translateX(0);
}

.page-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 300ms ease, transform 300ms ease;
}

/* ====================================== *\
   3.1 HERO
\* ====================================== */

.hero {
    display: flex;
    padding: 4.8rem;
    background: var(--cobalt);
    height: 100vh;
    min-height: 70rem;
    position: relative;
}

.hero nav {
    display: block;
    position: absolute;
    top: 3.2rem;
    left: 4.8rem;
    right: 4.8rem;
}

.hero nav ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2.4rem;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.hero nav ul li {
    display: block;
}

.hero nav ul li:last-child {
    margin-left: auto;
}

.hero nav ul li a {
    color: var(--cobalt);
    font-size: 1.6rem;
    text-decoration: none;
}

.hero nav ul li a:hover {
    color: var(--true-blue);
}

.hero .inner {
    display: flex;
    width: 100%;
    padding: 8rem 4.8rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    border-radius: 2.4rem;
    border: 4px solid var(--white);
    background: var(--white);
    position: relative;
    overflow: hidden;
}

.hero .container {
    z-index: 10;
    display: block;
}

.hero-flair-blue,
.hero-flair-yellow,
.hero-flair-red {
    z-index: 1;
    pointer-events: none;
}

.hero-flair-blue {
    display: block;
    width: 64rem;
    height: 64rem;
    background-color: var(--true-blue);
    opacity: 0.3;
    filter: blur(100px);
    border-radius: 100%;
    position: absolute;
    top: -20%;
    left: -20%;

    animation: moveInCircle 10s linear infinite;
}

.hero-flair-yellow {
    display: block;
    width: 64rem;
    height: 64rem;
    background-color: var(--mustard-yellow);
    opacity: 0.3;
    filter: blur(100px);
    border-radius: 100%;
    position: absolute;
    top: -44rem;
    right: 6.4rem;  

    animation: moveInCircleReverse 5s linear infinite;
}

.hero-flair-red {
    display: block;
    width: 64rem;
    height: 64rem;
    background-color: var(--blood-red);
    opacity: 0.3;
    filter: blur(100px);
    border-radius: 100%;
    position: absolute;
    bottom: -32rem;
    right: -32rem;

    animation: moveInCircle 5s linear infinite;
}

@keyframes moveInCircle {
    0% {
        transform: translate(20%, 0%);
    }
    25% {
        transform: translate(0%, 20%);
    }
    50% {
        transform: translate(-20%, 0%);
    }
    75% {
        transform: translate(0%, -20%);
    }
    100% {
        transform: translate(20%, 0%);
    }
}

@keyframes moveInCircleReverse {
    0% {
        transform: translate(-20%, 0%);
    }
    25% {
        transform: translate(0%, 20%);
    }
    50% {
        transform: translate(20%, 0%);
    }
    75% {
        transform: translate(0%, 20%);
    }
    100% {
        transform: translate(-20%, 0%);
    }
}

.hero h1,
.hero p {
    display: block;
    max-width: 75rem;
}

/* ====================================== *\
   3.2 WORK
\* ====================================== */

.z-grid {
    display: flex;
    flex-direction: column;
    gap: 6.4rem;
    margin-bottom: 12rem;
}

.z-grid-container {
    display: flex;
    flex-direction: row;
    gap: 15rem;
    align-items: center;
}

.z-grid .z-grid-container:nth-child(even) {
    flex-flow: row-reverse;
}
 
.z-grid-container .image {
    display: block;
    width: 50%;
}

.z-grid-container .image img {
    display: block;
    border-radius: 2.4rem;
    width: 100%;
}

.z-grid-container .details {
    display: block;
    width: 50%;
    max-width: 54rem;
}

.work-grid {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 3.2rem;
}

.work-grid a {
    display: block;
    position: relative;
    z-index: 1;
    border-radius: 1.2rem;
    overflow: hidden;
    border: 1px solid #cccccc;
    cursor: pointer;
}

.work-grid .overlay {
    width: 0;
    height: 0;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    padding: 2.4rem;
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: -100%;
    right: 0;
    left: 0;
    background:rgba(0,41,49,0.8);
    color: var(--white);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    z-index: 5;
    border-radius: 100%;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    cursor: pointer;
}

.work-grid a:hover .overlay {
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 1.2rem;
}

.work-grid .overlay p {
    width: 100%;
    display: block;
    padding-bottom: 0.8rem;
    text-align: center;
    font-size: 2rem;
}

.work-grid .overlay p:last-child {
    padding-bottom: 0;
}

.work-grid .overlay p span {
    font-size: 1.4rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.4rem;
}

.work-grid .overlay p span:after {
    content: "";
    display: block;
    width: 1.6rem;
    height: 1.6rem;
    background: url('../public/images/icons/ico-external-white.svg');
    background-size: 1.6rem 1.6rem;
}

.work-grid img {
    display: block;
    width: 100%;
    position: relative;
    z-index: 2;
    border-radius: 1.2rem;
}

.video-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    background-color: #000;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* ====================================== *\
   3.2.1 CASE STUDIES
\* ====================================== */

.hero.case-study {
    height: auto;
    min-height: 0;
}

.hero.case-study img {
    display: block;
    width: 100%;
    height: auto;
    border: 2px solid var(--white);
    border-radius: 2.4rem;
}

/* ====================================== *\
   3.3 ABOUT
\* ====================================== */

.hero.about .inner {
    padding: 0 16rem;
    text-align: left;
    align-items: stretch;
}

.hero.about .inner .columns {
    gap: 8rem;
    justify-content: space-between;
    width: 100%; 
    align-items: stretch;
    height: 100%;
    z-index: 10;
}

.hero.about .inner .columns .col {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.hero.about .inner .columns .half:first-child {
    max-width: 54rem;
    padding: 8rem 0;
}

.gallery {
    list-style: none;
    margin: 0 auto;
    padding: 0;
    overflow: hidden; /* Ensures gallery scrolls within the container */
    height: 100%; /* Container height for the gallery */
}

.gallery li {
    width: 100%;
    padding: 0.8rem 0;
    box-sizing: border-box;
}

.gallery li img {
    display: block;
    width: 100%;
    margin: 0;
    height: auto;
}


/* ====================================== *\
   3.4 SERVICES
\* ====================================== */

.service {
    display: block;
    padding: 4.8rem;
    border-radius: 1.2rem;
    border: 1px solid #727272;
}

.service .service-thumb {
    margin: 0 0 2.4rem 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 24rem;
    border-radius: 1.2rem;
}

.service .service-thumb.blue {
    background: #CDE6EC;
}

.service .service-thumb.red {
    background: #F8CFD1;
}

.service .service-thumb.yellow {
    background: #F5E6CC;
}

.service .service-thumb.cobalt {
    background: #CCD4D6;
}

.service .service-thumb.blue img {
    display: block;
    width: 23rem;
}

.service .service-thumb.red img {
    display: block;
    width: 17rem;
}

.service .service-thumb.yellow img {
    display: block;
    width: 23rem;
}

.service .service-thumb.cobalt img {
    display: block;
    width: 31rem;
}

.service ul {
    margin-left: 2.4rem;
}

.service ul li {
    display: list-item;
    list-style-type: disc;
    margin-bottom: 0.4rem;
}

/* ====================================== *\
   3.5 FAQ
\* ====================================== */

.accordion {
    width: 100%;
    max-width: 94rem;
    margin: 0 auto;
}
  
.accordion-item {
    background-color: var(--white);
    border: 4px solid var(--white);
    margin-bottom: 2.4rem;
    border-radius: 1.2rem;
    overflow: hidden;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
}

.accordion-toggle {
    display: none;
}
  
.accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.6rem 2.4rem;
    cursor: pointer;
    color: var(--cobalt);
    font-size: 2rem;
    font-weight: normal;
    background-color: transparent;
    border: none;
    outline: none;
    width: 100%;
    text-align: left;
    outline: none;
    transition: background-color 0.3s ease;
}
  
.accordion-icon {
    color: var(--cobalt);
    transition: transform 0.3s ease;
    font-size: 3.2rem;
    font-weight: normal;
}

.accordion-header:hover .accordion-icon {
    color: var(--true-blue);
}

.accordion-content {
    max-height: 0;
    padding: 0 2.4rem;
    font-size: 1.6rem;
    overflow: hidden;
    background-color: var(--white);
    color: var(--cobalt);
    transition: max-height 0.3s ease-in-out;
}
  
.accordion-toggle:checked ~ .accordion-content {
    padding: 0 2.4rem 2.4rem;
    max-height: 100%;
}

.flair-blue,
.flair-yellow,
.flair-red {
    z-index: 1;
    pointer-events: none;
}

.flair-blue {
    display: block;
    width: 64rem;
    height: 64rem;
    background-color: var(--true-blue);
    opacity: 0.3;
    filter: blur(100px);
    border-radius: 100%;
    position: absolute;
    top: 0;
    right: 0;

    animation: moveDown 10s linear infinite;
}

.flair-yellow {
    display: block;
    width: 64rem;
    height: 64rem;
    background-color: var(--mustard-yellow);
    opacity: 0.3;
    filter: blur(100px);
    border-radius: 100%;
    position: absolute;
    top: 50%;
    left: 0;  

    animation: moveDown 10s linear infinite;
}

.flair-red {
    display: block;
    width: 64rem;
    height: 64rem;
    background-color: var(--blood-red);
    opacity: 0.3;
    filter: blur(100px);
    border-radius: 100%;
    position: absolute;
    bottom: 0;
    right: 0;

    animation: moveUp 10s linear infinite;
}

@keyframes moveDown {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(80%);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes moveUp {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-20%);
    }
    100% {
        transform: translateY(0);
    }
}

/* ====================================== *\
   3.6 CONTACT
\* ====================================== */

.hero.contact {
    display: flex;
    padding: 4.8rem;
    background: var(--white);
    height: 100vh;
    min-height: 70rem;
}

.hero.contact .inner {
    display: flex;
    width: 100%;
    min-height: 60rem;
    padding: 8rem 4.8rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    border-radius: 2.4rem;
    background: 
        url('../public/images/glk-watermark.svg'),
        url('../public/images/glk-watermark.svg');
    background-position: 
        left -25% center,
        right -30% center;
    background-size: auto 80%;
    background-repeat: no-repeat;
    background-color: var(--cobalt);
    color: var(--white);
    position: relative;
    overflow: hidden;
}

.hero.contact .inner .container {
    max-width: 54rem;
    text-align: left;
}

.contact .eyebrow,
.eyebrow.yellow {
    color: var(--mustard-yellow);
}

ul.social {
    display: flex;
    flex-direction: row;
    gap: 1.6rem;
    margin-top: 3.2rem; 
}

ul.social li {
    display: block;
}

ul.social li a {
    display: block;
}

ul.social li a:hover {
    transform: scale(1.1,1.1) rotate(10deg);
}

ul.social li a img {
    display: block;
}

/* ====================================== *\
   3.7 SHOP
\* ====================================== */

.hero.shop {
    padding: 20rem 0 4.8rem 0;
    height: auto;
    display: block;
    position: relative;
}

.hero.shop .copy {
    display: block;
    margin: 0 auto 4.8rem auto;
    max-width: 54rem;
    color: var(--white);
    text-align: center;
    position: relative;
}

.hero.shop .copy .sticker {
    display: block;
    width: 12rem;
    height: 12rem;
    position: absolute;
    top: -7.2rem;
    left: -7.2rem;
    background: url(../public/images/stickers/sticker-closed.png);
    background-size: 12rem 12rem;
    background-repeat: no-repeat;
    background-position: center center;
    overflow: hidden;
    text-indent: -999999px;
}

/* ========================================================================== *\
   4. GENERAL CLASSES
\* ========================================================================== */

.hide-desktop {
    display: none;
}

.section {
    display: block;
    background: var(--white);
    padding: 8rem 0;
}

.section.light-blue {
    background: var(--light-blue);
}

.section.no-bottom {
    padding-bottom: 0;
}

.section .inner {
    padding: 0 4.8rem;
}

.section.dark {
    background: var(--cobalt);
    color: var(--white);
}

.section.dark .content {
    z-index: 10;
    position: relative;
}

.content {
	display: block;
	max-width: 114rem;
    margin: 0 auto;
}

.content.alt {
    max-width: 88rem;
}

.narrow {
    display: block;
    max-width: 65rem;
    margin: 0 auto;
}

.center {
    text-align: center;
}

.relative {
	position: relative;
}

.no-overflow {
    overflow: hidden;
}

.block {
    display: block;
}

.full {
    width: 100%;
}

.eyebrow {
    display: block;
    font-size: 1.4rem;
    font-weight: bold;
    letter-spacing: 2px;
    padding-bottom: 0.4rem;
    color: var(--true-blue);
    text-transform: uppercase;
}

.eyebrow.dark {
    color: var(--cobalt);
}

.btn {
	display: inline-block;
    border-radius: 100px;
    border: 2px solid var(--Cobalt, #002931);
    background: var(--Cobalt, #002931);
    color: var(--white);
    text-align: center;
    font-size: 1.6rem;
    font-style: normal;
    line-height: 125%;
    text-decoration: none;
    padding: 1.6rem 2.4rem;

    overflow: hidden;
    position: relative;
    transition: .2s transform ease-in-out;
    will-change: transform;
    z-index: 0;
}
  
.btn::after {
    background-color: var(--true-blue);
    border-radius: 3rem;
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-100%, 0) rotate(10deg);
    transform-origin: top left;
    transition: .2s transform ease-out;
    will-change: transform;
    z-index: -1;
}

.btn:hover::after {
    transform: translate(0, 0);
}
    
.btn:hover {
    color: var(--white);
    will-change: transform;
}

.btn.secondary {
    background: var(--white);
    color: var(--cobalt);
}

.btn.secondary:hover {
    color: var(--white);
}

.btn.tertiary {
    border: 1px solid var(--white);
    background: transparent;
    color: var(--white);
}

.btn.large {
    margin-top: 0.8rem;
    font-size: 2rem;
}

.btn.block {
    display: block;
    text-align: center;
}

.btn.sticky {
    display: flex;
    flex-direction: row;
    gap: 0.8rem;

    position: fixed;
    top: 2.4rem;
    left: 2.4rem;
    z-index: 999999;
    box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.20);

    background: var(--white);
    color: var(--true-blue);
    border-color: var(--true-blue);
}

.btn.sticky:before {
    content: "✖";
}

.btn.sticky:hover {
    color: var(--white);
}

.btn.work,
.btn.contact,
.btn.contact-alt,
.btn.case-study,
.btn.case-study-alt,
.btn.external {
    display: inline-flex;
    flex-direction: row;
    gap: 0.8rem;
    align-items: center;
}

.btn.external:before {
    content:"";
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    background: url(../public/images/icons/ico-external-white.svg);
}

.btn.work:before {
    content:"";
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    background: url(../public/images/icons/ico-design-white.svg);
}

.btn.case-study:before {
    content:"";
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    background: url(../public/images/icons/ico-case-study-white.svg);
}

.btn.case-study-alt:before {
    content:"";
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    background: url(../public/images/icons/ico-case-study-cobalt.svg);
}

.btn.case-study-alt:hover:before {
    content:"";
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    background: url(../public/images/icons/ico-case-study-white.svg);
}

.btn.contact-alt:before {
    content:"";
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    background: url(../public/images/icons/ico-contact-white.svg);
}

.btn.contact:before {
    content:"";
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    background: url(../public/images/icons/ico-contact-cobalt.svg);
}

.btn.contact:hover:before {
    content:"";
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    background: url(../public/images/icons/ico-contact-white.svg);
}

.button-group {
    display: flex;
    flex-direction: row;
    gap: 1.6rem;
}

.button-group.center {
    justify-content: center;
}

.clear {
    clear:both;
}

.clear.sm {
	height: 2rem !important;
}

.clear.md {
	height: 4rem !important;
}

.clear.lg {
	height: 6rem !important;
}

.clear.xl {
	height: 8rem !important;
}

.border-radius {
    border-radius: 1.2rem;
    display: block;
    border: 2px solid var(--white);
}

/* ========================================================================== *\
   5. COLUMNS
\* ========================================================================== */

.grid {
    display: grid;
    gap: 3.2rem;
    margin-bottom: 6.4rem;
}

.grid.half {
    grid-template-columns: repeat(2, 1fr);
}

.grid.third {
    grid-template-columns: repeat(3, 1fr);
}

.grid.shop img {
    display: block;
    border-radius: 1.2rem;
    padding: 2.4rem;
    background-color: var(--white);
}

.columns {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.columns .col {
	display: block;
}

.centerV {
	align-items: center;
}

.col.right {
	text-align: right;
}

.one_half {
	width:48%;
}

.one_third {
	width:30.66%;
}

.two_third {
	width:65.33%;
}

.one_fourth {
	width:22%;
}

.three_fourth{
	width:74%;
}

.one_fifth {
	width:16.8%;
}

.two_fifth{
	width:37.6%;
}

.three_fifth {
	width:58.4%;
}

.four_fifth {
	width:79.2%;
}

.one_sixth {
	width:13.33%;
}

.five_sixth {
	width:82.67%;
}