h1,
h2.large {
	font-size: 4rem;
	line-height: 115%;
}

h2 {
	font-size: 3.2rem;
	line-height: 115%;
	padding-bottom: 1.6rem;
}

h3 {
	font-size: 2.2rem;
	line-height: 125%;
}

h4 {
	font-size: 2rem;
}

p.large {
	font-size: 1.8rem;
}


.content {
	max-width: 100%;
}

.content.alt {
    max-width: 100%;
}

.narrow {
    max-width: 100%;
	padding: 0 2.4rem;
}

footer {
    padding: 2.4rem;
}

footer div {
	min-width: 0;
}

.hero {
   padding: 2.4rem;
   min-height: 0;
   height: auto;
}

.hero .inner {
    padding: 9.6rem 2.4rem 8rem 2.4rem;
    border-radius: 1.2rem;
}

.hero nav {
	top: 1.6rem;
	left: 2.4rem;
	right: 2.4rem;
}

.section {
    padding: 6.4rem 0;
}

.section .inner {
    padding: 0 2.4rem;
}

.section .inner .content {
	padding: 0;
}

.content {
	padding: 0 2.4rem;
}

.z-grid-container {
    gap: 4.8rem;
	align-items: flex-start;
}

.z-grid-container .details {
	line-height: 150%;
}

.z-grid-container {
	flex-direction: column;
    gap: 3.2rem;
    align-items: flex-start;
}

.z-grid .z-grid-container:nth-child(even) {
    flex-flow: column;
}

.z-grid-container .image {
    width: 100%;
}

.z-grid-container .details {
    width: 100%;
    max-width: 100%;
}

.hero.case-study img {
	border-radius: 1.2rem;
}

.hero.about .inner {
    padding: 4.8rem;
}

.hero.about .inner .columns {
	gap: 0;
}

.hero.about .inner .columns .half:first-child {
    max-width: 100%;
    padding: 0;
}

.gallery {
	display: none;
}

.clear.xl,
.clear.lg {
	height: 2.4rem !important;
}

.btn.sticky {
    top: 1.2rem;
    left: 1.2rem;
    padding: 1.2rem 2.4rem;
}

.hero.contact {
	padding: 2.4rem;
}

.hero.contact {
    height: auto;
    min-height: 0rem;
}

.hero.contact .inner {
    background-size: auto 50%;
    background-repeat: no-repeat;
    border-radius: 1.2rem;

	min-height: 0rem;
    padding: 4.8rem;
}

.work-grid {
    grid-template-columns: repeat(2,1fr);
	gap: 2.4rem;
}

.service {
    padding: 1.6rem;
	font-size: 1.4rem;
	line-height: 150%;
}

.service p {
	padding-bottom: 1.6rem;
}

.service .service-thumb {
    height: 18rem;
}

.service .service-thumb img {
	transform: scale(0.6, 0.6);
}

.service .columns {
	flex-flow: row wrap;
}

.service .columns ul {
	width: 100%;
	margin-left: 1.6rem;
}

.accordion {
    max-width: 100%;
}

.grid {
    gap: 2.4rem;
    margin-bottom: 4.8rem;
}

.hero.shop {
    padding: 16rem 0 4.8rem 0;
}

.hero.shop .copy {
    padding: 0 2.4rem;
}

.hero.shop .copy .sticker {
    top: -8rem;
    left: -4.8rem;
	transform: scale(0.8,0.8);
}

.accordion-item {
    margin-bottom: 1.6rem;
}

.accordion-header {
    padding: 0.8rem 1.6rem;
    font-size: 1.6rem;
}

.accordion-icon {
    font-size: 2.4rem;
}

.accordion-content {
    padding: 0 1.6rem;
	font-size: 1.4rem;
	line-height: 150%;
}

.btn {
	/* make smaller */
}

.hamburger {
	display: flex;
}

header {
    width: 6.4rem;
    height: 6.4rem;
	padding: 1.2rem 1.6rem;
	right: 0.8rem;
    left: inherit;
	top: inherit;
	bottom: 0.8rem;
	flex-direction: column;
	align-items: center;
    justify-content: center;
    border-radius: 100%;
}

header.expand {
    transform: none;
	height: 100%;
	flex-direction: column;
	justify-content: center;
    width: 100%;
    border-radius: 0;
    right: 0;
    left: inherit;
    bottom: 0;
    border: none;
}

header.expand nav {
	display: block;
}

header.hidden {
    transform: translateY(-150%) translateX(0);
}

header.visible {
    transform: translateY(0) translateX(0);
}

header .logo-mark {
    display: none;
}

header .logo-mark.active {
    display: block;
	position: absolute;
	top: 3.6rem;
	left: 3.2rem;
}

header img {
    width: 5.6rem;
	margin: 0 auto;
}

header nav {
    display: none;
    width: 100%;
	padding: 0;
}

header nav ul {
    flex-direction: column;
    gap: 2.4rem;
	max-width: 24rem;
	margin: 0 auto;
}

header nav ul li {
	display: block;
	width: 100%;
	text-align: center;
}

header nav ul li:first-child {
    margin-left: 0;
}

header nav ul li:last-child {
    margin-left: 0;
}

header nav ul li .btn {
    width: 100%;
	font-size: 1.6rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

header nav ul li a {
    color: var(--white);
    font-weight: normal;
    text-decoration: none;
    font-size: 2rem;
    overflow: hidden;
    display: block;
    position: relative;
    overflow: hidden;
}

header nav ul li a:after {
    content: "";
    display: block;
    width: 100%;
    height: 0.2rem;
    background: var(--turquoise);
    position: absolute;
    left: 100%;
    bottom: 0;
}

header nav ul li a:hover:after {
    left: 0;
}

.hide-desktop {
	display: block;
}

.btn {
	padding: 1.2rem 1.6rem;
}

.btn.external:before,
.btn.case-study-alt:before,
.btn.case-study:before,
.btn.work:before,
.btn.case-study-alt:hover:before,
.btn.contact-alt:before,
.btn.contact:before,
.btn.contact:hover:before {
    width: 2rem;
    height: 2rem;
	background-size: 2rem 2rem;
}

.columns.wrap {
	flex-flow: row wrap;
}

.columns.wrap .col {
	width: 100%;
}

.columns.wrap .col img {
	display: block;
	margin-bottom: 2.4rem;
}

.no-scroll {
    overflow: hidden;
}