header {
    width: 96rem;
}

.hero {
   padding: 2.4rem;
}

.z-grid-container {
    gap: 4.8rem;
}

.hero.about .inner {
    padding: 0 4.8rem;
}

.clear.xl {
	height: 4.8rem !important;
}

.btn.sticky {
    top: 1.2rem;
    left: 1.2rem;
    padding: 1.2rem 2.4rem;
}

.hero.contact .inner {
    background-size: auto 70%;
    background-repeat: no-repeat;
}