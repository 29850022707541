h1,
h2.large {
	font-size: 3.2rem;
	line-height: 115%;
}

h2 {
	font-size: 2.8rem;
	line-height: 115%;
	padding-bottom: 1.6rem;
}

h3 {
	font-size: 2rem;
	line-height: 125%;
}

h4 {
	font-size: 2rem;
}

p.large {
	font-size: 1.6rem;
}

p.XLarge {
    font-size: 2rem;
}

.content {
	max-width: 100%;
}

.content.alt {
    max-width: 100%;
}

.narrow {
    max-width: 100%;
	padding: 0 1.6rem;
}

footer {
    padding: 2.4rem 1.6rem 4.8rem 1.6rem;
	gap: 0rem;
	text-align: center;
    flex-direction: column;
	justify-content: flex-start;
	font-size: 1.6rem;
}

footer.alt {
    padding: 2.4rem 1.6rem 0 1.6rem;
}

footer.hide {
    display: none;
}

footer div {
	min-width: 0;
}

footer div:last-child {
    text-align: center;
}

.footer-logo {
    width: 9.6rem;
    margin-bottom: 2.4rem;
}

.hero {
   padding: 1.6rem;
   min-height: 80vh;
   height: auto;
}

.hero .inner {
    padding: 4.8rem 2.4rem;
	border-radius: 1.2rem;
}

.hero nav {
	display: none;
}

.hero-flair-blue,
.hero-flair-yellow,
.hero-flair-red {
	width: 40rem;
	height: 40rem;
}

.hero-flair-yellow {
	right: 0;
}

.hero-flair-blue {
	left: -20rem;
}

.logo {
    width: 18rem;
} 

.section {
    padding: 4.8rem 0;
}

.section.terms {
	padding-top: 8rem;
}

.section .inner {
    padding: 0 1.6rem;
}

.section .inner .content {
	padding: 0;
}

.content {
	padding: 0 1.6rem;
}

.z-grid {
	margin-bottom: 6.4rem;
}

.z-grid-container {
    gap: 4.8rem;
	align-items: flex-start;
}

.z-grid-container .details {
	line-height: 150%;
}

.z-grid-container {
	flex-direction: column;
    gap: 3.2rem;
    align-items: flex-start;
}

.z-grid .z-grid-container:nth-child(even) {
    flex-flow: column;
}

.z-grid-container .image {
    width: 100%;
}

.z-grid-container .details {
    width: 100%;
    max-width: 100%;
}

.hero.case-study {
	padding-top: 6.4rem;
}

.hero.case-study img {
	border-radius: 1.2rem;
}

.hero.about .inner {
    padding: 4.8rem 2.4rem;
}

.hero.about .inner .columns {
	gap: 0;
}

.hero.about .inner .columns .half:first-child {
    max-width: 100%;
    padding: 0;
}

.gallery {
	display: none;
}

.clear.xl,
.clear.lg,
.clear.md {
	height: 1.6rem !important;
}

.btn.sticky {
    top: 1.2rem;
    left: 1.2rem;
	font-size: 1.4rem;
    padding: 0.8rem 1.6rem;
	width: auto;
}

.hero.shop {
    padding: 12rem 0 4.8rem 0;
}

.hero.shop .copy {
    padding: 0 1.6rem;
}

.hero.contact {
	padding: 1.6rem;
}

.hero.contact {
    height: auto;
    min-height: 0rem;
}

.hero.contact .inner {
    border-radius: 1.2rem;
    background: 
        url('../public/images/glk-watermark.svg'),
        url('../public/images/glk-watermark.svg');
    background-position: 
        left -120px center,
        right -120px center;
    background-size: auto 50%;
    background-repeat: no-repeat;
    background-color: var(--cobalt);

	min-height: 0rem;
    padding: 4.8rem 2.4rem;
}

.work-grid {
    grid-template-columns: repeat(2,1fr);
	gap: 1.6rem;
}

.service {
    padding: 1.6rem;
	font-size: 1.4rem;
	line-height: 150%;
}

.service p {
	padding-bottom: 1.6rem;
}

.service .service-thumb {
    height: 18rem;
}

.service .service-thumb img {
	transform: scale(0.6, 0.6);
}

.service .columns {
	flex-flow: row wrap;
}

.service .columns ul {
	width: 100%;
	margin-left: 1.6rem;
}

.accordion {
    max-width: 100%;
}

.grid {
    gap: 2.4rem;
    margin-bottom: 4.8rem;
}

.grid.half {
    grid-template-columns: repeat(1, 1fr);
}

.grid.third {
    grid-template-columns: repeat(2, 1fr);
}

.hero.shop .copy .sticker {
    top: -8rem;
    left: -1.6rem;
	transform: scale(0.6,0.6);
}

.accordion-item {
    margin-bottom: 1.6rem;
}

.accordion-header {
    padding: 0.8rem 1.6rem;
    font-size: 1.6rem;
}

.accordion-icon {
    font-size: 2.4rem;
}

.accordion-content {
    padding: 0 1.6rem;
	font-size: 1.4rem;
	line-height: 150%;
}

.btn.large {
    margin-top: 0.8rem;
    font-size: 1.6rem;
}

.hamburger {
	display: flex;
	width: 2.4rem;
	height: 2.4rem;
}

.line {
    height: 3px;
}

.line.bottom.active {
    transform: translateY(-6px) rotate(-45deg);
}

header {
    width: 6.4rem;
    height: 6.4rem;
	padding: 1.2rem 1.6rem;
	right: 0.8rem;
    left: inherit;
	top: inherit;
	bottom: 0.8rem;
	flex-direction: column;
	align-items: center;
    justify-content: center;
    border-radius: 100%;
}

header.expand {
    transform: none;
	height: 100%;
	flex-direction: column;
	justify-content: center;
    width: 100%;
    border-radius: 0;
    right: 0;
    left: inherit;
    bottom: 0;
    border: none;
}

header.expand nav {
	display: block;
}

header.hidden {
    transform: translateY(0) translateX(0);
}

header.visible {
    transform: translateY(0) translateX(0);
}

header .logo-mark {
    display: none;
}

header .logo-mark.active {
    display: block;
	position: absolute;
	top: 3.6rem;
	left: 3.2rem;
}

header img {
    width: 5.6rem;
	margin: 0 auto;
}

header nav {
    display: none;
    width: 100%;
	padding: 0;
}

header nav ul {
    flex-direction: column;
    gap: 2.4rem;
	max-width: 24rem;
	margin: 0 auto;
}

header nav ul li {
	display: inline-block;
	text-align: center;
}

header nav ul li a {
    padding-bottom: 0.8rem;
}

header nav ul li:first-child {
    margin-left: 0;
}

header nav ul li:last-child {
    margin-left: 0;
}

header nav ul li .btn {
    min-width: 20rem;
	font-size: 1.6rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

header nav ul li a {
    color: var(--white);
    font-weight: normal;
    text-decoration: none;
    font-size: 2rem;
    overflow: hidden;
    display: block;
    position: relative;
    overflow: hidden;
}

header nav ul li a:after {
    content: "";
    display: block;
    width: 100%;
    height: 0.2rem;
    background: var(--turquoise);
    position: absolute;
    left: 100%;
    bottom: 0;
}

header nav ul li a:hover:after {
    left: 0;
}

.hide-desktop {
	display: block;
}

.btn {
	padding: 1.2rem 1.6rem;
	width: 100%;
	font-size: 1.6rem;
	justify-content: center;
	text-align: center;
}

.btn.external:before,
.btn.case-study-alt:before,
.btn.case-study:before,
.btn.work:before,
.btn.case-study-alt:hover:before,
.btn.contact-alt:before,
.btn.contact:before,
.btn.contact:hover:before {
    width: 2rem;
    height: 2rem;
	background-size: 2rem 2rem;
}

.columns.wrap {
	flex-flow: row wrap;
}

.columns.wrap .col {
	width: 100%;
}

.columns.wrap .col img {
	display: block;
	margin-bottom: 2.4rem;
}

.button-group {
	flex-direction: column;
}

.button-group .btn {
	text-align: center;
	justify-content: center;
}

.mobile-wrap-reverse {
	flex-flow: wrap-reverse;
}

.mobile-wrap-reverse .col {
	width: 100%;
	text-align: center;
}

ul.social {
    justify-content: center;
}

ul.social li {
    display: block;
	width: 4rem;
}

ul.social li a {
    display: block;
}

.no-scroll {
    overflow: hidden;
}

.eyebrow {
    font-size: 1.2rem;
}